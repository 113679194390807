import './BeehivSection.css';
import BeehivCard from './BeehivCard';
import { fakeDataString } from '../services/fakeData';
import { masterfulMindsUrl } from '../services/urls';
import { useEffect, useState } from 'react';
import masterfulMindsLogo from '../assets/images/Masterful_Minds_logo.jpeg';
import Button from './Button';
// import { makeGetRequest } from '../services/rest';

const BeehivSection = () => {
    const [fakeJson, setFakeJson] = useState(null);

    useEffect(() => {
        const getPosts = async () => {
            setFakeJson(null);

            if (!ignore) {
                const jsonData = JSON.parse(fakeDataString).data;
                setFakeJson(jsonData);
            }
        };

        let ignore = false;
        getPosts();

        return () => {
            ignore = true;
        };
    }, []);

    // useEffect(() => {
    //     const getFakeData = async () => {
    //         setFakeJson(null);

    //         const asdf = await makeGetRequest(fakeUrl);
    //         if (!ignore) {
    //             setFakeJson(asdf);
    //         }
    //     };

    //     let ignore = false;
    //     getFakeData();

    //     return () => {
    //         ignore = true;
    //     };
    // }, []);

    return (
        <section className="beehiv-section">
            <div className="container pt-20 pb-20">
                <div className="beehiv-blurb marcellus justify-center text-center px-10">
                    Recent episodes of <span className="bg-text-shadow">Masterful Minds</span>
                </div>
                <div className="flex justify-center align-center p-10">
                    <img src={masterfulMindsLogo} className="mm-logo" />
                </div>

                <div className="beehiv-cards mb-4">
                    {fakeJson &&
                        fakeJson.map((card) => (
                            <BeehivCard
                                key={card.id}
                                postId={card.id}
                                postTitle={card.title}
                                postDate={card.displayed_date}
                                postUrl={card.web_url}
                            />
                        ))}
                </div>

                <div className="flex justify-center items-center">
                    <a href={masterfulMindsUrl} target="_blank" rel="noopener noreferrer">
                        <Button btnText="More episodes" btnColor="#476c9b" />
                    </a>
                </div>
            </div>
        </section>
    );
};

export default BeehivSection;
