import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { calendlyUrl } from '../services/urls';
import './App.css';
import Modal from './Modal';
import Navbar from './Navbar';
import Home from './Home';
import Services from './Services';
import Footer from './Footer';
import Newsletter from './Newsletter';

function App() {
    const [modalVisibility, setModalVisibility] = useState(false);
    // const [hasSubscribed, setHasSubscribed] = useState(false);

    const handleShowModal = () => {
        setModalVisibility(true);
    };

    const handleHideModal = () => {
        setModalVisibility(false);
    };

    // const handleHasSubscribed = () => {
    //     setHasSubscribed(true);
    // };

    const calendlyIframe = `<iframe src="${calendlyUrl}" title="Book a free session" style="width:100%; border: 0.5px solid var(--lavender-web); border-radius: 4px; height:calc(85vh - 4rem);"></iframe>`;

    return (
        <BrowserRouter>
            <div className="App relative">
                {/* <Modal
                    visible={modalVisibility}
                    hasSubscribed={hasSubscribed}
                    onHideModal={handleHideModal}
                    onHasSubscribed={handleHasSubscribed}
                /> */}
                <Modal
                    visible={modalVisibility}
                    onHideModal={handleHideModal}
                    content={calendlyIframe}
                />
                <Navbar onShowModal={handleShowModal} />
                <Routes>
                    <Route path="/" element={<Home onShowModal={handleShowModal} />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/newsletter" element={<Newsletter />} />
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default App;
