import './Footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <div className="flex justify-center items-center h-full px-4 font-semibold gap-x-5 inter text-sm">
                <div>© 2024 21. Consulting LLC - All Rights Reserved</div>
            </div>
        </div>
    );
};

export default Footer;
