import './Testimonials.css';
import TestimonialCard from './TestimonialCard';

const Testimonials = () => {
    return (
        <section className="testimonials-section mt-10">
            <div className="container">
                <p className="leading-none text-[2rem] marcellus py-10 text-center">
                    What our clients are saying
                </p>
                <div className="mb-10">
                    <div className="flex justify-center">
                        <TestimonialCard
                            testimonial="Gereon's deep understanding of international procurement processes,
                    combined with his strategic mindset, has led to significant cost savings and
                    operational improvements."
                            author="Christian Botnariu"
                            role="CEO North America, DRS"
                        />
                    </div>

                    <div className="pt-4 flex justify-center">
                        <TestimonialCard
                            testimonial="Gereon is incredibly knowledgeable about global supply chain and procurement.
                    His knowledge of procurement extends beyond the automotive sector as he helped
                    me in a Sr. Sales role to develop best practices when negotiating contracts with
                    executives at Fortune 500 companies."
                            author="Luke Falasca"
                            role="Enterprise Account Executive, Highway"
                        />
                    </div>

                    <div className="pt-4 flex justify-center">
                        <TestimonialCard
                            testimonial="Gereon's cross-cultural experience and team-first communication style as
                    well as his determination and high ethical standards make him a leader of the
                    future."
                            author="Tom Loafman"
                            role="Chief Supply Chain Officer, Volkswagen"
                        />
                    </div>

                    <div className="pt-4 flex justify-center">
                        <TestimonialCard
                            testimonial="Gereon has been a tremendous resource providing thoughtful guidance and insights
                    into complex sales. Data-driven, yes. Process-oriented, yes. But also mindful of
                    the human psychology between two parties attempting to find a solution. This is
                    essential. I look forward to working closely with Gereon over the coming years
                    and look forward to seeing others make use of his talents."
                            author="Eric Thompson"
                            role="VP Strategic Sales Management, RXO"
                        />
                    </div>

                    <div className="pt-4 flex justify-center">
                        <TestimonialCard
                            testimonial="Gereon is an absolute legend. He exudes a remarkable combination of structure,
                    goal orientation, and exceptional planning skills. Moreover, he is a genuinely
                    kind individual with a keen sense of situational awareness. 21. Consulting
                    played an instrumental role in assisting us with the internationalization
                    process into the USA. For a German company to have a German partner on the
                    ground was nothing short of fantastic. We would gladly work with them again."
                            author="Roman Wenz"
                            role="CEO Otaro GmbH"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
