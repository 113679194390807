import './Notification.css';

const Notification = () => {
    return (
        <div className="outer-badge">
            <div className="inner-circle"></div>
        </div>
    );
};

export default Notification;
