import './Button.css';

/**
 *
 * @param {theme} Use 'light' or 'dark', a white btn with black text or black btn with white text
 * @param {btnText} What the button displays in the middle
 * @param {size} A 'regular' or 'large' sized button. Defualts to 'regular'
 * @param {onClick} The callback that gets handled by the parent component
 * @example <Button btnText="Submit" onClick={handleSubmit} />
 */
const Button = ({ btnText = 'click here', onClick, btnColor = '#226ce0' }) => {
    return (
        <button
            onClick={onClick}
            className="button"
            style={{ backgroundColor: `${btnColor}`, border: `1px solid ${btnColor}` }}
        >
            {btnText}
        </button>
    );
};
// className={`button border-solid border border-blue-500 shadow shadow-blue-500/50`}
export default Button;
