import Hero from './Hero';
import StorySection from './StorySection';
import Testimonials from './Testimonials';

const Home = ({ onShowModal }) => {
    return (
        <>
            <Hero onShowModal={onShowModal} />
            <StorySection />
            <Testimonials />
        </>
    );
};

export default Home;
