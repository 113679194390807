import './Logo.css';

const Logo = ({ color = '#2a2c24' }) => {
    return (
        <div className="logo">
            <span style={{ color: `${color}` }} className="anton cursor-pointer">
                21
            </span>
            <span style={{ backgroundColor: `${color}` }} className="logo-period ml-0.5"></span>
        </div>
    );
};

export default Logo;
