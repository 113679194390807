import './Hero.css';
import Button from './Button.js';

const Hero = ({ hasSubscribed, onShowModal }) => {
    return (
        <section className="hero-section">
            <div className="container pt-20 pb-20">
                <h1 className="big-blurb">
                    Optimize Your International{' '}
                    <span className="accent-color">Supply Chain & Procurement</span> Strategies
                    {/* Negotiate and close $5M+ contracts{' '}
                    <span className="accent-color">with less risk</span> */}
                </h1>

                <div className="action-block px-4">
                    {/* <div className="action-item">
                        <h1 className="little-blurb-heading">Gain more business</h1>
                        <p className="little-blurb">
                            <span className="accent-color font-bold">Identify Opportunities: </span>
                            We&apos;ll pinpoint key industry prospects and connect you with major
                            clients, aligning your offerings with their needs.
                        </p>
                        <h1 className="little-blurb-heading">Increase your margins</h1>
                        <p className="little-blurb">
                            <span className="accent-color font-bold">Analyze Your Market: </span>
                            Dive into market conditions and supply chains to uncover where you can
                            cut costs and boost efficiency.
                        </p>
                        <h1 className="little-blurb-heading">Feel confident in any negotiation</h1>
                        <p className="little-blurb">
                            <span className="accent-color font-bold">
                                Advanced Negotiation Skills
                            </span>
                            Learn & apply sophisticated negotiation techniques to confidently manage
                            complex deals and secure favorable terms.
                        </p>
                    </div> */}
                    <div className="action-item">
                        <p className="little-blurb">
                            <span className="accent-color font-bold">
                                Leverage our global market experience and insights:{' '}
                            </span>
                            Gain a competitive edge with our extensive knowledge of international
                            supply chains.
                        </p>
                        <p className="little-blurb">
                            <span className="accent-color font-bold">
                                Multilingual team for seamless international operations:{' '}
                            </span>
                            Ensure effective communication and coordination with our team fluent in
                            multiple languages.
                        </p>
                        <p className="little-blurb">
                            <span className="accent-color font-bold">
                                Proven track record with $3 billion in contracts negotiated:{' '}
                            </span>
                            Trust in our success to deliver exceptional value and savings through
                            expert contract negotiations.
                        </p>
                    </div>
                </div>

                <div className="subscribe-block px-4">
                    <h1 className="little-blurb-heading justify-center">Start here</h1>
                    <div className="little-blurb-heading justify-center">
                        <Button
                            btnText={hasSubscribed ? 'Booked!' : 'Book a free strategy session'}
                            onClick={onShowModal}
                            btnColor="#2a2c24"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
