import './StorySection.css';
import headshot from '../assets/images/Gereon_closeup.png';
import { useRef, useEffect } from 'react';
import Logo from './Logo';

const StorySection = () => {
    const blurbRefs = [
        {
            blurb: "In my experience, most Supply Chain Consulting firms don't have in depth knowledge & tell you what you already know.",
            ref: useRef(null),
        },
        {
            blurb: "You don't get much value and you're not making any progress.",
            ref: useRef(null),
        },
        {
            blurb: '21 Consulting is changing that.',
            ref: useRef(null),
        },
        {
            blurb: 'Our approach is not to give advice and walk away.',
            ref: useRef(null),
        },
        {
            blurb: 'We aim to be a hands-on partner, delivering not only advice, but tangible results.',
            ref: useRef(null),
        },
        {
            blurb: 'How?',
            ref: useRef(null),
        },
        {
            blurb: 'Our team has more than 10 years of experience negotiating contracts worth over $3,000,000,000 at Forbes 25 companies.',
            ref: useRef(null),
        },
        {
            blurb: 'Our cultural knowhow in Mexico, the US and Germany distinguishes us from the competition.',
            ref: useRef(null),
        },
        {
            blurb: 'We speak Spanish, German & English.',
            ref: useRef(null),
        },
        {
            blurb: "Thanks to our extensive experience, we made the mistakes we're helping you avoid.",
            ref: useRef(null),
        },
        {
            blurb: 'This has led to the proven frameworks and strategies that guarantee success. The result...?',
            ref: useRef(null),
        },
        {
            blurb: 'Strategies and execution that elevate your company to the next level.',
            ref: useRef(null),
        },
        {
            blurb: 'Our approach is personalized & all about real progress.',
            ref: useRef(null),
        },
        {
            blurb: "The Supply Chain is complicated enough, let's not make it more difficult.",
            ref: useRef(null),
        },
    ];

    useEffect(() => {
        const observers = blurbRefs.map((blurbRef) => {
            // eslint-disable-next-line
            const options = {
                rootMargin: '0px 0px -25% 0px',
                threshold: 0.5,
            };

            let isLeaving = false;
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    const element = entry.target;

                    if (entry.isIntersecting) {
                        isLeaving = true;
                        element.classList.add('intersecting');
                    } else if (isLeaving) {
                        isLeaving = false;
                        element.classList.remove('intersecting');
                    }
                });
            }, options);

            if (blurbRef.ref.current) {
                observer.observe(blurbRef.ref.current);
            }

            return observer;
        });

        return () => {
            observers.forEach((observer) => {
                observer.disconnect();
            });
        };
    }, [blurbRefs]);

    return (
        <section className="story-section pb-10">
            <div className="container">
                {/* Headshot */}
                <div className="flex justify-center p-14">
                    <div className="head-shot">
                        <img src={headshot} />
                    </div>
                </div>

                <div className="mb-12 story-section-heading text-white text-center">
                    My Name is Gereon Hempel. CEO & Founder of 21 Consulting.
                </div>

                <div className="blurbs">
                    {blurbRefs.map((blurbRef) => (
                        <div key={blurbRef.blurb} ref={blurbRef.ref} className="blurb-item">
                            {blurbRef.blurb}
                        </div>
                    ))}
                </div>
                <div className="pt-2 flex justify-center">
                    <Logo color="#fff" />
                </div>
            </div>
        </section>
    );
};

export default StorySection;
