export const fakeData = {
    data: [
        {
            id: 'post_00000000-0000-0000-0000-000000000011',
            subtitle: 'New post subtitle',
            title: 'Masterful Terms of Procurement',
            authors: ['Gereon Hempel'],
            created: 1666800076,
            status: 'confirmed',
            publish_date: 1666800076,
            displayed_date: 1709601974,
            split_tested: true,
            subject_line: 'Check this out',
            preview_text: 'More news on the horizon',
            slug: 'string',
            thumbnail_url: 'string',
            web_url: 'https://masterfulminds.beehiiv.com/p/masterful-terms-procurement',
            audience: 'free',
            platform: 'web',
            content_tags: ['string'],
            meta_default_description: 'A post with great content',
            meta_default_title: 'My great post',
            content: {
                free: {
                    web: '<HTML><body><h1>New Post</h1></body></HTML>',
                    email: '<HTML><body><h1>New Post</h1></body></HTML>',
                    rss: '<HTML><body><h1>New Post</h1></body></HTML>',
                },
                premium: {
                    web: '<HTML><body><h1>New Post</h1></body></HTML>',
                    email: '<HTML><body><h1>New Post</h1></body></HTML>',
                },
            },
            stats: {
                email: {
                    recipients: 100,
                    opens: 50,
                    unique_opens: 45,
                    open_rate: 0,
                    clicks: 10,
                    unique_clicks: 8,
                    click_rate: 0,
                    unsubscribes: 1,
                    spam_reports: 1,
                },
                web: {
                    views: 200,
                    clicks: 40,
                },
                clicks: [
                    {
                        url: 'string',
                        email: {
                            clicks: 10,
                            unique_clicks: 8,
                            click_through_rate: 30,
                        },
                        web: {
                            clicks: 10,
                            unique_clicks: 8,
                            click_through_rate: 30,
                        },
                        total_clicks: 10,
                        total_unique_clicks: 8,
                        total_click_through_rate: 30,
                    },
                ],
            },
            hidden_from_feed: false,
        },
        {
            id: 'post_00000000-0000-0000-0000-000000000010',
            subtitle: 'New post subtitle',
            title: 'Masterful Trial & Error',
            authors: ['Gereon Hempel'],
            created: 1666800076,
            status: 'confirmed',
            publish_date: 1666800076,
            displayed_date: 1666800076,
            split_tested: true,
            subject_line: 'Check this out',
            preview_text: 'More news on the horizon',
            slug: 'string',
            thumbnail_url: 'string',
            web_url: 'https://masterfulminds.beehiiv.com/p/masterful-trial-error',
            audience: 'free',
            platform: 'web',
            content_tags: ['string'],
            meta_default_description: 'A post with great content',
            meta_default_title: 'My great post',
            content: {
                free: {
                    web: '<HTML><body><h1>New Post</h1></body></HTML>',
                    email: '<HTML><body><h1>New Post</h1></body></HTML>',
                    rss: '<HTML><body><h1>New Post</h1></body></HTML>',
                },
                premium: {
                    web: '<HTML><body><h1>New Post</h1></body></HTML>',
                    email: '<HTML><body><h1>New Post</h1></body></HTML>',
                },
            },
            stats: {
                email: {
                    recipients: 100,
                    opens: 50,
                    unique_opens: 45,
                    open_rate: 0,
                    clicks: 10,
                    unique_clicks: 8,
                    click_rate: 0,
                    unsubscribes: 1,
                    spam_reports: 1,
                },
                web: {
                    views: 200,
                    clicks: 40,
                },
                clicks: [
                    {
                        url: 'string',
                        email: {
                            clicks: 10,
                            unique_clicks: 8,
                            click_through_rate: 30,
                        },
                        web: {
                            clicks: 10,
                            unique_clicks: 8,
                            click_through_rate: 30,
                        },
                        total_clicks: 10,
                        total_unique_clicks: 8,
                        total_click_through_rate: 30,
                    },
                ],
            },
            hidden_from_feed: false,
        },
        {
            id: 'post_00000000-0000-0000-0000-000000000009',
            subtitle: 'New post subtitle',
            title: 'Masterful Health',
            authors: ['Gereon Hempel'],
            created: 1666800076,
            status: 'confirmed',
            publish_date: 1666800076,
            displayed_date: 1666800076,
            split_tested: true,
            subject_line: 'Check this out',
            preview_text: 'More news on the horizon',
            slug: 'string',
            thumbnail_url: 'string',
            web_url: 'https://masterfulminds.beehiiv.com/p/masterful-health',
            audience: 'free',
            platform: 'web',
            content_tags: ['string'],
            meta_default_description: 'A post with great content',
            meta_default_title: 'My great post',
            content: {
                free: {
                    web: '<HTML><body><h1>New Post</h1></body></HTML>',
                    email: '<HTML><body><h1>New Post</h1></body></HTML>',
                    rss: '<HTML><body><h1>New Post</h1></body></HTML>',
                },
                premium: {
                    web: '<HTML><body><h1>New Post</h1></body></HTML>',
                    email: '<HTML><body><h1>New Post</h1></body></HTML>',
                },
            },
            stats: {
                email: {
                    recipients: 100,
                    opens: 50,
                    unique_opens: 45,
                    open_rate: 0,
                    clicks: 10,
                    unique_clicks: 8,
                    click_rate: 0,
                    unsubscribes: 1,
                    spam_reports: 1,
                },
                web: {
                    views: 200,
                    clicks: 40,
                },
                clicks: [
                    {
                        url: 'string',
                        email: {
                            clicks: 10,
                            unique_clicks: 8,
                            click_through_rate: 30,
                        },
                        web: {
                            clicks: 10,
                            unique_clicks: 8,
                            click_through_rate: 30,
                        },
                        total_clicks: 10,
                        total_unique_clicks: 8,
                        total_click_through_rate: 30,
                    },
                ],
            },
            hidden_from_feed: false,
        },
    ],
    limit: 0,
    page: 1,
    total_results: 0,
    total_pages: 0,
};

export const fakeDataString =
    '{"data":[{"id":"post_00000000-0000-0000-0000-000000000013","title":"Masterful Risk Mitigation","displayed_date":1713758400,"web_url":"https://masterfulminds.beehiiv.com/p/masterful-risk-mitigation"},{"id":"post_00000000-0000-0000-0000-000000000012","title":"Masterful Interruptions","displayed_date":1710129600,"web_url":"https://masterfulminds.beehiiv.com/p/masterful-interruptions"},{"id":"post_00000000-0000-0000-0000-000000000011","title":"Masterful Terms of Procurement","displayed_date":1709601974,"web_url":"https://masterfulminds.beehiiv.com/p/masterful-terms-procurement"},{"id":"post_00000000-0000-0000-0000-000000000010","title":"Masterful Trial & Error","displayed_date":1666800076,"web_url":"https://masterfulminds.beehiiv.com/p/masterful-trial-error"},{"id":"post_00000000-0000-0000-0000-000000000009","title":"Masterful Health","displayed_date":1666800076,"web_url":"https://masterfulminds.beehiiv.com/p/masterful-health"},{"id":"post_00000000-0000-0000-0000-000000000008","title":"Masterful Deals","displayed_date":1707714000,"web_url":"https://masterfulminds.beehiiv.com/p/masterful-deals"}]}';

export const fakeUrl = 'https://my-json-server.typicode.com/drewcoparker/json-server/db';
