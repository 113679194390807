import './Services.css';
import Logo from './Logo';

const Services = () => {
    return (
        <section className="services-section">
            <div className="container pt-20 pb-20">
                <p className="alt-big-blurb marcellus text-center mb-16">Our Services</p>

                <div className="service-container mb-16">
                    <div className="basis-4/12">
                        <p className="service-title marcellus text-xl">Procurement Strategies</p>
                    </div>
                    <div className="basis-8/12">
                        <p className="service-blurb mb-10">
                            <span className="accent-color font-bold">Analyze Your Market: </span>
                            Dive into market conditions and supply chains to uncover where you can
                            cut costs and boost efficiency.
                        </p>
                        <p className="service-blurb mb-10">
                            <span className="accent-color font-bold">Category Management: </span>
                            Tailor your procurement strategy to each category for smarter spending
                            and better supplier performance.
                        </p>
                        <p className="service-blurb mb-10">
                            <span className="accent-color font-bold">Process Optimization: </span>
                            Streamline your procurement with tech and smarter workflows for quicker,
                            clearer operations.
                        </p>
                        <p className="service-blurb mb-10">
                            <span className="accent-color font-bold">
                                Strengthen Supplier Ties:{' '}
                            </span>
                            Forge stronger relationships with suppliers for more value, beyond just
                            contracts.
                        </p>
                        <p className="service-blurb mb-10">
                            <span className="accent-color font-bold">Stay Compliant: </span>
                            Adopt sustainable practices and meet regulations to enhance your
                            reputation and reduce risks.
                        </p>
                        <p className="service-blurb">
                            <span className="accent-color font-bold">
                                Safeguard Your Operations:{' '}
                            </span>
                            Fortify your contracts and due diligence to protect against legal and
                            financial risks.
                        </p>
                    </div>
                </div>
                <hr />

                <div className="service-container my-16">
                    <div className="basis-4/12">
                        <p className="service-title marcellus text-xl">Sales Strategies</p>
                    </div>
                    <div className="basis-8/12">
                        <p className="service-blurb mb-10">
                            <span className="accent-color font-bold">Identify Opportunities: </span>
                            We&apos;ll pinpoint key industry prospects and connect you with major
                            clients, aligning your offerings with their needs.
                        </p>
                        <p className="service-blurb mb-10">
                            <span className="accent-color font-bold">
                                Customized Sales Strategies:{' '}
                            </span>
                            We tailor our approach with playbooks that engage clients by addressing
                            their specific challenges and decision-making processes.
                        </p>
                        <p className="service-blurb mb-10">
                            <span className="accent-color font-bold">
                                Advanced Negotiation Skills:{' '}
                            </span>
                            Learn & apply sophisticated negotiation techniques to confidently manage
                            complex deals and secure favorable terms.
                        </p>
                        <p className="service-blurb mb-10">
                            <span className="accent-color font-bold">
                                Strategic Relationship Management:{' '}
                            </span>
                            Develop enduring relationships with key stakeholders, leveraging
                            insights into their expectations to build trust and rapport.
                        </p>
                        <p className="service-blurb">
                            <span className="accent-color font-bold">Strategic Messaging: </span>
                            Learn to articulate your unique value proposition, positioning your
                            solutions with major clients and setting you apart from competitors.
                        </p>
                    </div>
                </div>
                <hr />

                <div className="service-container mt-16">
                    <div className="basis-4/12">
                        <p className="service-title marcellus text-xl">Market Entry Analysis</p>
                    </div>
                    <div className="basis-8/12">
                        <p className="service-blurb mb-10">
                            <span className="accent-color font-bold">
                                Understand The Landscape:{' '}
                            </span>
                            Get tailored insights into Mexico, the US, Canada, and Germany, focusing
                            on what matters for your business to thrive.
                        </p>
                        <p className="service-blurb mb-10">
                            <span className="accent-color font-bold">Build Key Connections: </span>
                            Tap into our network to form essential local partnerships, easing your
                            entry and growth in these markets.
                        </p>
                        <p className="service-blurb mb-10">
                            <span className="accent-color font-bold">Craft Your Strategy: </span>
                            We&apos;ll design a market entry plan for each country, customized to
                            your goals for precise, impactful results.
                        </p>
                        <p className="service-blurb mb-10">
                            <span className="accent-color font-bold">
                                Stay Compliant, Minimize Risks:{' '}
                            </span>
                            Lean on us for expert guidance through complex regulations, keeping your
                            operations smooth and safe.
                        </p>
                        <p className="service-blurb">
                            <span className="accent-color font-bold">
                                Connect with Your Audience:{' '}
                            </span>
                            Adapt your message with our help to resonate deeply with local cultures,
                            ensuring your brand speaks directly to their needs.
                        </p>
                    </div>
                </div>
                <div className="flex justify-center pt-32">
                    <Logo />
                </div>
            </div>
        </section>
    );
};

export default Services;
