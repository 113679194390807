import './Modal.css';
import close from '../assets/icons/close_alt.svg';
// import SubscribeInput from './SubscribeInput.js';
// import Button from './Button.js';

// const Modal = ({ visible, hasSubscribed, onHideModal, onHasSubscribed, children }) => {
const Modal = ({ visible, onHideModal, content = '<div>modal body</div' }) => {
    return (
        <section className={`modal-section ${visible ? 'visible' : 'invisible'}`}>
            <div
                className="backdrop z-10 absolute top-0 right-0 bottom-0 left-0 bg-black"
                style={{ opacity: visible ? 0.75 : 0, transition: 'opacity 0.3s ease' }}
            ></div>
            <div className="modal z-20">
                <div className="relative">
                    <div className="">
                        <img
                            src={close}
                            className="close-btn cursor-pointer"
                            onClick={() => onHideModal()}
                        />
                    </div>
                </div>
                <div className="modal-body">
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                    {/* <div className="grid flex-grow">
                        <div className="upper-grid-area modal-heading flex flex-col justify-center text-center">
                            {hasSubscribed
                                ? 'Thank you for subscribing!'
                                : 'Lorem ipsum dolor sit amet'}
                        </div>

                        <div className="bottom-grid-area flex flex-col justify-center px-4">
                            <div className="flex flex-wrap justify-center gap-x-2 gap-y-2">
                                {visible && !hasSubscribed && (
                                    <>
                                        <SubscribeInput />
                                        <div className="subscribe-button">
                                            <Button
                                                btnText="Contact"
                                                onClick={() => onHasSubscribed()}
                                            />
                                        </div>
                                    </>
                                )}
                                {hasSubscribed && (
                                    <div className="subscribed-body raleway text-center">
                                        An email has been sent to your address
                                    </div>
                                )}
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default Modal;
