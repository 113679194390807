import moment from 'moment';

export const formatUnixTime = (unixTimeStamp, datePattern) => {
    return moment.unix(unixTimeStamp).format(datePattern);
};

export const getBeehivPostNumberFromId = (id) => {
    const strMatches = id.match(/\d+$/);

    return parseInt(strMatches[0], 10);
};
