import './TestimonialCard.css';
import quote from '../assets/icons/dub-quote.svg';

const TestimonialCard = ({ testimonial, author, role }) => {
    return (
        <section className="testimonial-card grow marcellus relative">
            <div className="testimonial-card-body relative p-4">
                <img src={quote} className="pb-4" width="32px" />
                <p className="leading-5 px-4">{testimonial}</p>
                <div className="flex justify-end leading-4 pt-4">
                    <div className="flex flex-col">
                        <p className="font-semibold">{author}</p>
                        <p className="text-sm">{role}</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestimonialCard;
