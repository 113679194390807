import './BeehivCard.css';
import headshot from '../assets/images/Gereon_closeup.png';
import { formatUnixTime, getBeehivPostNumberFromId } from '../utilities/utils';
import arrow from '../assets/icons/arrow-forward.svg';

const BeehivCard = ({ postId, postTitle, postDate, postUrl }) => {
    return (
        <>
            <section
                className="card grow raleway relative"
                onClick={() => location.assign(postUrl)}
            >
                <div className="card-top flex items-center justify-between gap-x-4">
                    <div className="card-head-shot">
                        <img src={headshot} />
                    </div>
                    <div className="card-title">Masterful Minds</div>
                    <div className="text-xs">Ep #{getBeehivPostNumberFromId(postId)}</div>
                </div>
                <div className="card-body relative">
                    <div className="card-center flex items-center  text-2xl">
                        <div>
                            <div className="post-title marcellus">{postTitle}</div>
                            <div className="text-xs raleway">
                                {formatUnixTime(postDate, 'MMM DD, YYYY')}
                            </div>
                        </div>
                    </div>
                    <div className="card-arrow p-2">
                        <img src={arrow} width="24px" />
                    </div>
                </div>
            </section>
        </>
    );
};

export default BeehivCard;
