// import { useState, useEffect, useRef } from 'react';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Navbar.css';
import menu from '../assets/icons/menu.svg';
import close from '../assets/icons/close.svg';
import linkedIn from '../assets/icons/linkedIn.svg';
// import twentyOne from '../assets/images/twentyOneConsultingLogo_v7.svg';
import Button from './Button.js';
import Notification from './Notification.js';
import Logo from './Logo.js';
// import SubscribeInput from './SubscribeInput.js';
import { linkedInUrl } from '../services/urls';

const Navbar = ({ hasSubscribed, onShowModal }) => {
    const [navOpen, setNavOpen] = useState(false);
    // const [subscribeFormOpen, setSubscribeFormOpen] = useState(false);
    // const subscribeFormRef = useRef(null);

    // useEffect(() => {
    //     const handleDocumentClick = (event) => {
    //         if (!subscribeFormRef.current?.contains(event.target)) {
    //             setSubscribeFormOpen(false);
    //             hideModal();
    //         }
    //     };

    //     if (subscribeFormOpen) {
    //         document.addEventListener('click', handleDocumentClick);
    //     }

    //     return () => {
    //         document.removeEventListener('click', handleDocumentClick);
    //     };
    // }, [subscribeFormOpen]);

    // const handleSubscribeButtonClick = (event) => {
    //     event.stopPropagation();

    //     if (subscribeFormOpen === false) {
    //         onShowModal();
    //     }

    //     setSubscribeFormOpen(!subscribeFormOpen);
    // };

    // const handleFormButtonClick = (event) => {
    //     event.stopPropagation();
    //     setSubscribeFormOpen(false);
    //     hideModal();
    // };

    // const handleSubscribeButtonClick = (event) => {
    //     event.stopPropagation();
    //     onShowModal();
    //     window.location = 'https://calendly.com/21consulting/freestrategysession';
    // };

    // const handleBookButtonClick = (event) => {
    //     event.stopPropagation();
    //     window.location = 'https://calendly.com/21consulting/freestrategysession';
    // };
    // const navItemsClassnames = ``;

    const handleBookButtonClick = (event) => {
        event.stopPropagation();

        onShowModal();
    };

    return (
        <section className="navbar w-full">
            <div className="container nav-flex flex items-center h-full">
                <Link to="/">
                    <Logo />
                </Link>
                <div className="flex nav-items items-center text-sm tracking-wider poppins">
                    <NavLink to="/" className="nav-item ml-6 relative">
                        Home
                    </NavLink>
                    <NavLink to="/services" className="nav-item ml-6 relative">
                        Services
                    </NavLink>
                    <NavLink to="/newsletter" className="nav-item ml-6 relative">
                        Newsletter
                    </NavLink>
                    <a
                        className="nav-item ml-6"
                        href={linkedInUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img width="24px" height="24px" src={linkedIn} />
                    </a>
                </div>
                <div className="navbar-button cursor-pointer">
                    <Button
                        btnText={hasSubscribed ? 'Booked!' : 'Book a free strategy session'}
                        onClick={handleBookButtonClick}
                        btnColor="#2a2c24"
                    />
                    {hasSubscribed && (
                        <div className="notification-badge">
                            <Notification />
                        </div>
                    )}
                    {/* <Button btnText="Talk to us now" onClick={handleSubscribeButtonClick} />

                    {subscribeFormOpen && (
                        <div className="tooltip" ref={subscribeFormRef}>
                            <div className="subscribe-input flex gap-x-2 gap-y-2">
                                <SubscribeInput />
                                <div className="subscribe-button">
                                    <Button btnText="Contact" onClick={handleFormButtonClick} />
                                </div>
                            </div>
                        </div>
                    )} */}
                </div>
                <img
                    src={navOpen ? close : menu}
                    width="40px"
                    height="40px"
                    className="hamburger-menu cursor-pointer"
                    onClick={() => setNavOpen(!navOpen)}
                />
            </div>
            {navOpen && (
                <div className="hidden-navbar text-sm tracking-wide poppins">
                    <NavLink to="/" className="nav-item ml-6 relative">
                        Home
                    </NavLink>
                    <NavLink to="/services" className="nav-item ml-6 relative">
                        Services
                    </NavLink>
                    <NavLink to="/newsletter" className="nav-item ml-6 relative">
                        Newsletter
                    </NavLink>
                    <a
                        className="nav-item ml-6"
                        href={linkedInUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img width="24px" height="24px" src={linkedIn} />
                    </a>
                </div>
            )}
        </section>
    );
};

export default Navbar;
